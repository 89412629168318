import { isBefore, parse } from 'date-fns';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import useApiCall from '../../../hooks/useApiCall';
import usersService from '../../../services/usersService';
import removeDuplicates from '../../../utils/removeDuplicates';

type SelectedTargetType = {
  label: string;
  value: string;
};

type SubscriberFromApi = {
  assinatura_status: string;
  celular: string;
  data_ativacao: string;
  email: string;
  empresa: string;
  nome: string;
  _id: string;
  company?: {
    id?: string;
    nome?: string;
  };
  cupom_utilizado?: {
    code?: string;
  }
};

type UserFromApi = {
  email: string;
  nome: string;
  assinatura_status: 'Ativa' | 'Cancelada';
  data_ativacao?: string;
}

export default function usePushNotifications() {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTarget, setSelectedTarget] = useState<SelectedTargetType>({} as SelectedTargetType);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [willSend, setWillSend] = useState(false);
  const [selectedAgeRange, setSelectedAgeRange] = useState([0, 13]);
  const [choosedAgeRange, setChoosedAgeRange] = useState([0, 13]);

  const [isScheduled, setIsScheduled] = useState(false);
  const [date, setDate] = useState('');
  const [canSubmit, setCanSubmit] = useState(false);

  const [targetOptions, setTargetOptions] = useState([
    { value: 'Teste', label: 'Teste' },
    { value: 'Assinantes', label: 'Assinantes' },
    { value: 'Cadastrados', label: 'Cadastrados' },
    { value: 'Todos', label: 'Todos' },
    { value: 'horribleEngagement', label: 'Engajamento Péssimo' },
    { value: 'badEngagement', label: 'Engajamento Ruim' },
    { value: 'mediumEngagement', label: 'Engajamento Médio (alerta)' },
    { value: 'goodEngagement', label: 'Engajamento Bom' },
  ]);

  const { apiCall } = useApiCall();

  function handleNotificationMessageChange(event: ChangeEvent<HTMLTextAreaElement>) {
    setNotificationMessage(event.target.value);
  }

  function handleScheduledDateChange(event: ChangeEvent<HTMLInputElement>) {
    const parsedDate = parse(event.target.value, "yyyy-MM-dd'T'HH:mm", new Date());
    const isSelectedDateBefore = isBefore(parsedDate, new Date());

    if (isSelectedDateBefore) {
      toast.error('Selecione uma data e hora futura.');
      setCanSubmit(false);
      return;
    }
    setCanSubmit(true);
    setDate(event.target.value);
  }

  const handleAgeSelection = useCallback((age: number[]) => {
    setChoosedAgeRange(age);
  }, []);

  const loadCompaniesAndUsers = useCallback(async () => {
    await apiCall({
      apiToCall: usersService.getUsersList,
      onStartLoad: () => setIsLoading(true),
      onEndLoad: () => setIsLoading(false),
      actionAfterResponse: (apiResponse) => {
        if (apiResponse.error) {
          toast.error(`Não foi possível carregar todas as opções de envio de notificações push (${apiResponse.error})`, {
            toastId: 'getSubscribersFail',
          });
          setIsLoading(false);
          return;
        }
        const subscribersList: SubscriberFromApi[] = apiResponse.list;
        const companiesFromSubscribers = subscribersList?.map((subscriber) => (
          {value: subscriber.company?.id || null, label: subscriber.company?.nome || 'B2C'}
        ));

        const companies = removeDuplicates(companiesFromSubscribers);
        const companiesFiltered = companies.filter((company) => company.label !== 'JaSouKiddle');

        const usersList: UserFromApi[] = apiResponse.list;

        const mappedUsersToTargetOptions = usersList.map((usr) => ({
          value: usr.email, label: usr.nome
        }))

        setTargetOptions((prevState: { value: string, label: string }[]) => (
          removeDuplicates([...prevState, ...companiesFiltered, ...mappedUsersToTargetOptions])
        ));
      },
      catchMessage: 'Não foi possível carregar as empresas para envio de notificações push'
    })
  }, [apiCall, setTargetOptions]);

  useEffect(() => {
    if (targetOptions.length <= 8) {
      loadCompaniesAndUsers();
    }
  }, [loadCompaniesAndUsers, targetOptions.length])

  return {
    selectedTarget,
    targetOptions,
    setSelectedTarget,
    handleNotificationMessageChange,
    notificationMessage,
    isLoading,
    setWillSend,
    willSend,
    isScheduled,
    setIsScheduled,
    date,
    handleScheduledDateChange,
    canSubmit,
    selectedAgeRange,
    handleAgeSelection,
    setSelectedAgeRange,
    choosedAgeRange,
  };
}
