import { ChangeEvent } from 'react';
import FilterRadioButton from '../../../../components/FilterRadioButtons';
import { FilterRadioButtonsContainer } from '../../../../components/FilterRadioButtonsContainer';
import OpacityAnimation from '../../../../components/OpacityAnimation';
import {
  InputSearchContainer, SearchContainer,
} from './styles';

interface SearchInterface {
  searchTerm: string;
  onChangeSearchTerm: (event: ChangeEvent<HTMLInputElement>) => void;
  pushStatus: 'scheduled' | 'sent' | '';
  setPushStatus: (status: 'scheduled' | 'sent' | '') => void;
}

export default function Search({
  searchTerm,
  onChangeSearchTerm,
  pushStatus,
setPushStatus,
}: SearchInterface) {

  return (
    <OpacityAnimation delay={0.1}>
      <SearchContainer>
        <InputSearchContainer>
          <input
            value={searchTerm}
            type="text"
            placeholder="Pesquisar mensagem da notificação"
            onChange={onChangeSearchTerm}
          />
        </InputSearchContainer>

        <FilterRadioButtonsContainer>
          <FilterRadioButton
            selected={pushStatus === ''}
            onClick={() => setPushStatus('')}
          >
            Todas
          </FilterRadioButton>

          <FilterRadioButton
            selected={pushStatus === 'scheduled'}
            onClick={() => setPushStatus('scheduled')}
          >
            Agendadas
          </FilterRadioButton>

          <FilterRadioButton
            selected={pushStatus === 'sent'}
            onClick={() => setPushStatus('sent')}
          >
            Enviadas
          </FilterRadioButton>
        </FilterRadioButtonsContainer>
      </SearchContainer>
    </OpacityAnimation>
  );
}
