import { Dispatch, SetStateAction } from 'react';
import MyModal from '../../../components/Modal';
import { PushType } from '../types';

interface DeletePushModalInterface {
  pushBeingDeleted: PushType;
  setDeleteModalShow: Dispatch<SetStateAction<boolean>>;
  deletePush: (pushId: string) => void;
  deleteModalShow: boolean;
}

export default function DeletePushModal({
  pushBeingDeleted,
  setDeleteModalShow,
  deletePush,
  deleteModalShow,
}: DeletePushModalInterface) {
  return (
    <MyModal
      title={`Cancelar agendamento de notificação`}
      closeButtonLabel="Fechar"
      actionButtonLabel="Confirmar"
      modalBody={(
        <>
          <div>
            Tem certeza que deseja cancelar o envio da notificação
            {' '}
            <strong>{pushBeingDeleted.message}</strong>
            {' '}
            ?
          </div>
        </>
)}
      onClose={() => setDeleteModalShow(false)}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      onAction={() => deletePush(pushBeingDeleted.id!)}
      show={deleteModalShow}
      type="deleteAction"
    />
  );
}
