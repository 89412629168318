import { format } from "date-fns";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import useApiCall from "../../../hooks/useApiCall";
import pushsService from "../../../services/pushsService";
import ExportXlsx from "../../../utils/ExportXlsx";
import { PushType, SentPushType } from "../types";

export default function useCancelPush() {
  const [isLoading, setIsLoading] = useState(false);
  const [doesListApiHasError, setDoesListApiHasError] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');
  const [pushStatus, setPushStatus] = useState<'scheduled' | 'sent' | ''>('scheduled');
  const [scheduledPushs, setScheduledPushs] = useState<PushType[]>([]);

  const [pushBeingDeleted, setPushBeingDeleted] = useState<PushType>({} as PushType);
  const [pushBeingEditted, setPushBeingEditted] = useState<PushType>({} as PushType);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [createModalShow, setCreateModalShow] = useState(false);

  const isFirstRender = useRef(true);

  const { apiCall } = useApiCall();

  function handleChangeSearchTerm(event: React.ChangeEvent<HTMLInputElement>) {
    setSearchTerm(event.target.value);
  }

  function handleOpenDeleteModal(push: PushType) {
    setPushBeingDeleted(push);
    setDeleteModalShow(true);
  }

  function handleOpenEditModal(push: PushType) {
    setPushBeingEditted(push);
    setEditModalShow(true);
  }

  const filteredScheduledPushs = useMemo(() => (
    scheduledPushs.filter((push) => (
      push.message.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (push.status === pushStatus || !pushStatus)
    ))
  ), [scheduledPushs, searchTerm, pushStatus]);

  const loadScheduledPushs = useCallback(async () => {
    await apiCall({
      apiToCall: pushsService.getPushsList,
      onStartLoad: () => { setIsLoading(true) },
      onEndLoad: () => { setIsLoading(false) },
      actionAfterResponse: (response) => {
        setDoesListApiHasError(false);
        if (response.success) {
          setScheduledPushs(response.data as PushType[]);
          return;
        }
        toast.error('Não foi possível carregar as notificações. Por favor, tente novamente');
      },
      catchMessage: 'Não foi possível carregar as notificações. Por favor, tente novamente',
      catchAction: () => { setDoesListApiHasError(true) },
    })
  }, [apiCall]);

  const downloadSentReport = useCallback(async (scheduleId: string) => {
    await apiCall({
      apiToCall: pushsService.getSentPushsReport,
      queryParams: { id: scheduleId },
      onStartLoad: () => { setIsLoading(true) },
      onEndLoad: () => { setIsLoading(false) },
      actionAfterResponse: (response) => {
        if (!response.success) {
          toast.error('Não foi possível baixar o relatório de notificações enviadas. Por favor, tente novamente');
          return;
        }

        const sentPushsReport: SentPushType[] = response.data as SentPushType[];
        const mappedSentPushsReport = sentPushsReport.map((sentPush) => ({
          'Usuário': sentPush.user.nome,
          'Celular': sentPush.user.celular,
          'Email': sentPush.user.email,
          'Push ID': sentPush.user.push_id,
          'Público Alvo': sentPush.scheduledPush.target,
          'Mensagem': sentPush.scheduledPush.message,
          'Programada Para': format(new Date(sentPush.scheduledPush.sendAt), 'dd/MM/yyyy HH:mm:ss'),
          'Enviada Em': sentPush.scheduledPush.sentAt ? format(new Date(sentPush.scheduledPush.sentAt), 'dd/MM/yyyy HH:mm:ss') : 'Não Enviada',
          'Resposta OneSignal': sentPush.oneSignalResponse,
        }));

        ExportXlsx({ data: mappedSentPushsReport, filename: 'Relatório de Notificações Enviadas' });
      },
      catchMessage: 'Não foi baixar o relatório de notificações enviadas. Por favor, tente novamente',
    })
  }, [apiCall]);

  const deletePush = useCallback(async (pushBeingDeletedId: string) => {
    await apiCall({
      apiToCall: pushsService.removePush,
      queryParams: { id: pushBeingDeletedId },
      onStartLoad: () => { setIsLoading(true) },
      onEndLoad: () => { setIsLoading(false) },
      actionAfterResponse: (response) => {
        if (!response.success) {
          toast.error('Erro ao cancelar notificação. Tente novamente.');
          return;
        }

        setDeleteModalShow(false);
        loadScheduledPushs();
        toast.success('Notificação cancelada com sucesso');
      },
      catchMessage: 'Erro ao cancelar notificação. Tente novamente',
    })
  }, [apiCall, loadScheduledPushs]);

  const editPush = useCallback(async (pushBeingEdittedId: string, newPushObject: PushType) => {
    await apiCall({
      apiToCall: pushsService.updatePush,
      queryParams: { id: pushBeingEdittedId },
      reqBody: JSON.stringify(newPushObject),
      onStartLoad: () => { setIsLoading(true) },
      onEndLoad: () => { setIsLoading(false) },
      actionAfterResponse: (response) => {
        if (!response.success) {
          toast.error('Erro ao editar notificação. Tente novamente.');
          return;
        }

        setEditModalShow(false);
        loadScheduledPushs();
        toast.success('Notificação editada com sucesso');
      },
      catchMessage: 'Erro ao editar notificação. Tente novamente',
    })
  }, [apiCall, loadScheduledPushs]);

  const createPush = useCallback(async (newPushObject: PushType) => {
    await apiCall({
      apiToCall: pushsService.createPush,
      reqBody: JSON.stringify(newPushObject),
      onStartLoad: () => { setIsLoading(true) },
      onEndLoad: () => { setIsLoading(false) },
      actionAfterResponse: (response) => {
        if (!response.success) {
          toast.error('Erro ao criar notificação. Tente novamente.');
          return;
        }

        loadScheduledPushs();
        setCreateModalShow(false);
        toast.success('Notificação criada com sucesso');
      },
      catchMessage: 'Erro ao criar notificação. Tente novamente',
    })
  }, [apiCall, loadScheduledPushs]);

  function handleTryAgain() {
    setDoesListApiHasError(false);
    loadScheduledPushs();
  }

  useEffect(() => {
    loadScheduledPushs();

    if (isFirstRender.current) {
      isFirstRender.current = false;

      toast.info('Bem-vindo(a) ao gerenciamento de notificações! As mensagens podem levar até 10 minutos para serem enviadas após a hora de seu agendamento, ou após a função de enviar agora ser acionada.', { autoClose: false })
    }
  }, [loadScheduledPushs]);

  return {
    isLoading,
    searchTerm,
    handleChangeSearchTerm,
    scheduledPushs,
    filteredScheduledPushs,
    doesListApiHasError,
    handleOpenDeleteModal,
    pushBeingDeleted,
    setDeleteModalShow,
    deletePush,
    deleteModalShow,
    handleTryAgain,
    handleOpenEditModal,
    pushBeingEditted,
    setEditModalShow,
    editPush,
    editModalShow,
    downloadSentReport,
    pushStatus,
    setPushStatus,
    createPush,
    createModalShow,
setCreateModalShow,
  }
}
