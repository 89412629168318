import { AnimatePresence } from 'framer-motion';
import {
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';

// // pages
// import RegistrationData from '../pages/RegistrationData';
import NotFound from '../pages/NotFound';
// import ChangePassword from '../pages/RegistrationData/ChangePassword';

import Activities from '../pages/Activities';
import EditActivity from '../pages/Activities/EditActivity';
import NewActivity from '../pages/Activities/NewActivity';

import Partners from '../pages/Partners';
import EditPartner from '../pages/Partners/EditPartner';
import NewPartner from '../pages/Partners/NewPartner';

import Users from '../pages/Users';

import Categories from '../pages/Activities/Categories';
import ActivityPedagogicalProgram from '../pages/Activities/PedagogicalProgram';
import ActivityProgram from '../pages/Activities/Program';
import ActivitiesApprove from '../pages/ActivitiesApprove';
import ActivityCreateApprovement from '../pages/ActivitiesApprove/ActivityCreateApprovement';
import Books from '../pages/Books';
import BookCategories from '../pages/Books/Categories';
import EditBook from '../pages/Books/EditBook';
import NewBook from '../pages/Books/NewBook';
import ChangePassword from '../pages/ChangePassword';
import Companies from '../pages/Companies';
import Collaborators from '../pages/Companies/Collaborators';
import CompanyDashboard from '../pages/Companies/Dashboard';
import EditCompany from '../pages/Companies/EditCompany';
import NewCompany from '../pages/Companies/NewCompany';
import Cupons from '../pages/Cupons';
import Dashboard from '../pages/Dashboard';
import DisabledDates from '../pages/DisabledDates';
import Financial from '../pages/Financial';
import Forum from '../pages/Forum';
import ForumInternal from '../pages/ForumInternal';
import GivenClasses from '../pages/GivenClasses';
import AllowRelatoriesDownloads from '../pages/GivenClasses/AllowRelatoriesDownloads';
import EditGivenClass from '../pages/GivenClasses/EditGivenClass';
import NewGivenClass from '../pages/GivenClasses/NewGivenClass';
import Highlights from '../pages/Highlights';
import InternalUsers from '../pages/InternalUsers';
import Pushs from '../pages/Pushs';
import Schedules from '../pages/Schedules';
import Settings from '../pages/Settings';
import Subscribers from '../pages/Subscribers';
import EditUser from '../pages/Users/EditUser';

// import Financial from '../pages/Financial';

export default function AppRoutes() {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Routes
        location={location}
        key={location.pathname}
      >
        <Route path="/" element={<Dashboard />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/forum" element={<Forum />} />
        <Route path="/forumInternal" element={<ForumInternal />} />
        <Route path="/subscribers" element={<Subscribers />} />

        <Route path="/activities" element={<Activities />} />
        <Route path="/activities/:id" element={<EditActivity />} />
        <Route path="/activities/:id/program" element={<ActivityProgram />} />
        <Route path="/activities/:id/pedagogicalProgram" element={<ActivityPedagogicalProgram />} />
        <Route path="/activities/new" element={<NewActivity />} />
        <Route path="/activities/categories" element={<Categories />} />

        <Route path="/books" element={<Books />} />
        <Route path="/books/:id" element={<EditBook />} />
        <Route path="/books/new" element={<NewBook />} />
        <Route path="/books/categories" element={<BookCategories />} />

        <Route path="/activitiesApprove" element={<ActivitiesApprove />} />
        <Route path="/activitiesApprove/:id" element={<ActivityCreateApprovement />} />

        <Route path="/disabledDates" element={<DisabledDates />} />

        <Route path="/givenClasses" element={<GivenClasses />} />
        <Route path="/givenClasses/new" element={<NewGivenClass />} />
        <Route path="/givenClasses/allowDownloads" element={<AllowRelatoriesDownloads />} />
        <Route path="/givenClasses/:givenClassId" element={<EditGivenClass />} />

        <Route path="/partners" element={<Partners />} />
        <Route path="/partners/new" element={<NewPartner />} />
        <Route path="/partners/:id" element={<EditPartner />} />

        <Route path="/users" element={<Users />} />
        <Route path="/users/:id" element={<EditUser />} />

        <Route path="/internalUsers" element={<InternalUsers />} />

        <Route path="/schedules" element={<Schedules />} />

        <Route path="/cupons" element={<Cupons />} />

        <Route path="/companies" element={<Companies />} />
        <Route path="/companies/new" element={<NewCompany />} />
        <Route path="/companies/:id" element={<EditCompany />} />
        <Route path="/companies/dashboard/:companyId/:companyName" element={<CompanyDashboard />} />
        <Route path="/companies/collaborators/:companyId/:companyName" element={<Collaborators />} />

        <Route path="/financial" element={<Financial />} />

        <Route path="/highlights" element={<Highlights />} />

        <Route path="/changePassword" element={<ChangePassword />} />

        <Route path="/pushs" element={<Pushs />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </AnimatePresence>
  );
}
