import RangeSlider from 'react-range-slider-input';
import Select from 'react-select';
import styled from 'styled-components';
import { CustomStyle } from '../../../../components/CustomSelectStyle';
import FilterRadioButton from "../../../../components/FilterRadioButtons";
import { FilterRadioButtonsContainer } from "../../../../components/FilterRadioButtonsContainer";
import FormGroup from "../../../../components/FormGroup";
import Input from '../../../../components/Input';
import MyModal from "../../../../components/Modal";
import Textarea from '../../../../components/Textarea';
import { PushType } from '../../types';
import usePushNotifications from "../usePushNotifications";

const Group = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
  position: relative;
  bottom: 10px;
  margin: 16px;
  margin-top: 24px;

  @media(max-width: 500px) {
      width: 50%;
      margin-top: 8px;
    }
  header {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 8px;
  }
`;

export default function CreatePushModal({
  setCreateModalShow,
  createPush,
  createModalShow
}: {
  setCreateModalShow: (show: boolean) => void;
  createPush: (push: PushType) => void;
  createModalShow: boolean;
}) {
  const {
    selectedTarget,
    targetOptions,
    setSelectedTarget,
    handleNotificationMessageChange,
    notificationMessage,
    isLoading,
    setWillSend,
    willSend,
    isScheduled,
    setIsScheduled,
    date,
    handleScheduledDateChange,
    canSubmit,
    selectedAgeRange,
    handleAgeSelection,
    setSelectedAgeRange,
    choosedAgeRange,
  } = usePushNotifications();

  return (
    <MyModal
    show={createModalShow}
    onClose={() => {
      setCreateModalShow(false);
      setWillSend(false);
    }}
    onAction={willSend ? () => createPush({
      message: notificationMessage,
      sendAt: isScheduled ? new Date(date) : new Date(),
      target: selectedTarget.value,
      kidsAge: selectedAgeRange[1] === 13 && selectedAgeRange[0] === 0 ? undefined : choosedAgeRange,
    }) : () => setWillSend(true)}
    isActionButtonDisabled={isScheduled ? (!selectedTarget.value || !notificationMessage || !date || !canSubmit) : (!selectedTarget.value || !notificationMessage)}
    title="Enviar notificação"
    type="action"
    closeButtonLabel="Fechar"
    actionButtonLabel={willSend ? 'Confirmar envio' : isScheduled ? 'Agendar notificação' : 'Enviar notificação'}
    minHeight="230px"
    modalBody={(
      <>
      <FilterRadioButtonsContainer style={{ marginBottom: '16px'}}>
        <FilterRadioButton selected={!isScheduled} onClick={() => setIsScheduled(false)}>
          Enviar agora
        </FilterRadioButton>
        <FilterRadioButton selected={isScheduled} onClick={() => setIsScheduled(true)}>
          Agendar envio
        </FilterRadioButton>
      </FilterRadioButtonsContainer>
        <FormGroup>
          <label htmlFor="contactName">Selecione o público alvo ou usuário específico</label>
          <Select
            value={
                  {
                    value: selectedTarget.value,
                    label: selectedTarget.label,
                  }
                }
            options={targetOptions}
            isDisabled={isLoading}
            isLoading={isLoading}
            onChange={(opt) => {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              setSelectedTarget({ value: opt!.value, label: opt!.label });
            }}
            styles={CustomStyle}
            classNamePrefix="react-select"
            className="react-select-container"
          />
        </FormGroup>

        <Group>
          <header>
            Idade crianças:
            {' '}
            {(selectedAgeRange[1] === 13 && selectedAgeRange[0] === 0) ? 'Todas' : (
              `${selectedAgeRange[0]} - ${selectedAgeRange[1]} anos`
            )}
          </header>
          <RangeSlider
            min={0}
            max={13}
            value={selectedAgeRange}
            onThumbDragEnd={() => handleAgeSelection(selectedAgeRange)}
            onInput={(evt: number[]) => setSelectedAgeRange(evt)}
            className="single-thumb"
          />
        </Group>

        {isScheduled && (
          <FormGroup>
            <label htmlFor="contactName">Selecione a data e hora</label>
            <Input
              type='datetime-local'
              value={date}
              onChange={(event) => handleScheduledDateChange(event)}
            />
          </FormGroup>
        )}

        <FormGroup>
          <label htmlFor="contactName">Escreva a mensagem</label>
          <Textarea
            placeholder="Escreva aqui a mensagem a ser enviada"
            onChange={(event) => handleNotificationMessageChange(event)}
            value={notificationMessage}
          />
        </FormGroup>
      </>
)}
  />
  )
}
