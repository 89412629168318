import pathName from "../pathName";
import { ReqBodyType } from "./types/reqBody";
import HttpClient from "./utils/HttpClient";

interface PushsServiceInterface {
  id?: string;
  reqBody?: ReqBodyType;
  token: string;
}

class PushsService {
  private readonly httpClient: HttpClient;
  constructor() {
    this.httpClient = new HttpClient(pathName);
  }

  getPushsList = async ({
    token,
  }: PushsServiceInterface) => {
    return this.httpClient.get({
      path: '/pushs',
      token,
    });
  }

  createPush = async ({
    token,
    reqBody,
  }: PushsServiceInterface) => {
    return this.httpClient.post({
      path: '/pushs',
      token,
      reqBody,
    });
  }

  updatePush = async ({
    id,
    token,
    reqBody,
  }: PushsServiceInterface) => {
    return this.httpClient.put({
      path: `/pushs/${id}`,
      token,
      reqBody,
    });
  }

  removePush = async ({
    id,
    token,
  }: PushsServiceInterface) => {
    return this.httpClient.delete({
        path: `/pushs/${id}`,
        token,
      });
  }

  getSentPushsReport = async ({
    token,
    id,
  }: PushsServiceInterface) => {
    return this.httpClient.get({
      path: `/pushs/${id}`,
      token,
    });
  }
}

export default new PushsService();
