import { format } from 'date-fns';
import { Col, Container, Row } from 'react-bootstrap';
import download from '../../../../assets/images/icons/download.svg';
// import edit from '../../../../assets/images/icons/edit.svg';
import trash from '../../../../assets/images/icons/trash.svg';
import OpacityAnimation from '../../../../components/OpacityAnimation';
import { PushType } from '../../types';
import {
  Card,
} from './styles';

interface ListInterface {
  filteredList: PushType[];
  onOpenDeleteModal: (pushBeingDeleted: PushType) => void;
  onOpenEditModal: (pushBeingEditted: PushType) => void;
  downloadSentReport: (scheduleId: string) => void;
}

export default function List({
  filteredList,
  onOpenDeleteModal,
  // onOpenEditModal,
  downloadSentReport,
}: ListInterface) {
  return (
    <Container>
      <Row xs={1} md={2} lg={2}>
        {filteredList?.map((push) => (
          <Col key={push.id}>
            <OpacityAnimation delay={0.1}>
              <Card title="Expandir detalhes">
                <div className="info">
                  <div className="card-title">
                    <strong>{format(new Date(push.sendAt), 'dd/MM/yyyy HH:mm:ss')}</strong>

                    <small className={push.status === 'scheduled' ? '' : 'green'}>{push.status === 'scheduled' ? 'Agendada' : 'Enviada'}</small>
                  </div>
                  <span>
                    Público: {push.target}
                  </span>
                  <span>
                    Enviada em: {push.sentAt ? format(new Date(push.sentAt), 'dd/MM/yyyy HH:mm:ss') : 'Não Enviada'}
                  </span>
                  <span>
                    Mensagem: {push.message}
                  </span>
                </div>
                <div className="actions">
                  {push.status === 'scheduled' && (
                    <>
                      {/* <button
                        type="button"
                        onClick={() => onOpenEditModal(push)}
                      >
                        <img src={edit} alt="" title={`Alterar Dados`} />
                      </button> */}
                      <button
                        type="button"
                        onClick={() => onOpenDeleteModal(push)}
                      >
                        <img src={trash} alt="" title={`Cancelar envio`} />
                      </button>
                    </>
                  )}

                  {push.status === 'sent' && (
                    <button
                      type="button"
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      onClick={() => downloadSentReport(push.id!)}
                    >
                      <img src={download} alt="" title={`Baixar relatório`} className='primaryColor' />
                    </button>
                  )}
                </div>
              </Card>
            </OpacityAnimation>
          </Col>
        ))}
      </Row>
    </Container>
  );
}
